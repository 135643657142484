<template>
    <v-container fluid>
        <v-form @submit.prevent="getAdministrators('search')">
            <v-btn
                v-show="$vuetify.breakpoint.xsOnly && can(['administrator'])"
                :to="{ name: 'administrator.create' }"
                color="indigo"
                dark
                fab
                fixed
                top
                right
                class="v-btn--add-form-top"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer"
                                v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12" sm="8">
                                <v-subheader class="headline">{{ $t("search_administrator") }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly && can(['administrator'])"
                                   cols="12" sm="4" class="text-right">
                                <v-btn :to="{ name: 'administrator.create' }"
                                       color="indigo" dark>
                                    {{ $t("create") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="administrator" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field
                                        v-model="administrator"
                                        type="text"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        prepend-icon="mdi-account"
                                        :label="$t('client_name')"
                                        color="primary"
                                        clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="email" rules="email"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field
                                        v-model="email"
                                        type="email"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        prepend-icon="mdi-email"
                                        :label="$t('email')"
                                        color="primary"
                                        clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="phone" rules="phone"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field
                                        v-model="phoneRaw"
                                        type="tel"
                                        v-mask="phoneMask"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        prepend-icon="mdi-phone"
                                        :label="$t('phone')"
                                        color="primary"
                                        clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-0 py-6">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems"
                                          :disabled="loading" :label="$t('items_per_page')"
                                          prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          hide-details dense>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t("search") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat light>
            <v-card-text class="px-0">
                <v-data-table :headers="filteredHeaders" :items="administrators"
                              :options.sync="options" :page.sync="page"
                              :items-per-page="perPage"
                              :server-items-length="totalAdministrators"
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                              :loading="loading" :locale="lang"
                              @page-count="pageCount = options.pageCount = Number($event)"
                              :loading-text="$t('loading_please_wait')"
                              class="elevation-1" hide-default-footer>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? " (" + $t("deleted") + ")" : "" }}
                        </div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t("yes") : $t("no") }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="!can(['administrator'])"
                                       @click="editAdmin(item)" icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t("edit") }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="totalAdministrators > 1" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="item.deleted"
                                       @click="deleteAdmin(item)" icon>
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t("delete") }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-show="totalAdministrators > perPage"
                              v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading">
                </v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate"
import {mask} from "vue-the-mask"
import {mapGetters} from "vuex"

export default {
    name: "Administrators",
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            fab: false,
            phoneRaw: null,
            administrator: null,
            email: null,
            administrators: [],
            administratorItems: [],
            sortBy: "name",
            sortDir: true,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalAdministrators: 0,
            options: {},
            loading: false,
            headers: [
                {
                    text: this.$t("client_name"),
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                {
                    text: this.$t("email"),
                    sortable: false,
                    value: "email",
                },
                {
                    text: this.$t("phone"),
                    sortable: true,
                    value: "phone",
                },
                {
                    text: this.$t("active"),
                    align: "center",
                    sortable: true,
                    value: "active",
                    width: 110,
                },
                {
                    text: this.$t("actions"),
                    align: "center",
                    value: "action",
                    sortable: false,
                    width: 120,
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["lang", "itemsPerPage", "perPageItems", "phoneMask"]),
        phone: function () {
            return this.changePhone(this.phoneRaw);
        },
        filteredHeaders() {
            return this.headers.filter((h) => !h.hide);
        },
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage;
    },
    watch: {
        options: {
            handler() {
                this.getAdministrators();
            },
            deep: false,
        },
    },
    methods: {
        editAdmin(item) {
            this.$router.push({
                name: "administrator.edit",
                params: {
                    id: item.id,
                },
            });
        },
        async deleteAdmin(item) {
            if (confirm(this.$t("delete_administrator"))) {
                var _this = this;
                this.loading = true;
                await this.$http
                    .delete(`admin/admin/${item.id}`)
                    .then((res) => {
                        this.$toastr.success(
                            this.$t("administrator_has_been_deleted")
                        );
                        this.getAdministrators();
                    })
                    .catch((err) => {
                        this.$toastr.error(
                            this.$t("administrator_has_not_been_deleted")
                        );
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (
                                    hasOwnProperty.call(err.body.errors, prop)
                                ) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0],
                                        ]);
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message);
                            }
                        }
                    })
                    .finally((end) => {
                        this.loading = false;
                    });
            }
        },
        async getAdministrators(type) {
            this.loading = true;
            const {sortBy, sortDesc, page, itemsPerPage} = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? "asc" : "desc";
            }
            if (page !== undefined) {
                params.page = page;
            }
            if (type === 'search') {
                params.page = 1
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }
            if (this.administrator) {
                params.administrator = this.administrator;
            }
            if (this.phone) {
                params.phone = this.phone;
            }
            if (this.email) {
                params.email = this.email;
            }
            await this.$http
                .get("admin/admin", {
                    params: params,
                })
                .then((res) => {
                    this.administrators = res.body.data;
                    this.page = res.body.meta.current_page;
                    this.totalAdministrators = res.body.meta.total;
                    this.pageCount = res.body.meta.last_page;
                })
                .catch((err) => {
                    this.administrators = [];
                    this.totalAdministrators = 0;
                    this.$toastr.error(
                        this.$t("failed_to_get_list_administrators")
                    );
                })
                .finally((end) => {
                    this.loading = false;
                });
        },
    },
};
</script>
